<template>
  <div>
    <!------------------begin navigation Drawer----------------------->
    <v-card>
      <v-navigation-drawer
        v-model="drawer"
        app
        :width="345"
        color="accent"
        dark
      >
        <template v-slot:prepend>
          <div class="text-center pa-4 mt-2" v-if="auth_user">
            <img
              v-if="auth_user.institution && auth_user.institution.logo_url"
              height="120"
              :src="bucketUrl + auth_user.institution.logo_url"
              alt="Logo"
            />
            <img
              v-else
              height="50"
              src="/images/school-try-logo.png"
              alt="institution_placeholder"
            />
            <h4>
              <strong>{{ auth_user.institution.name }}</strong>
            </h4>
          </div>
        </template>

        <v-list nav dense class="pt-7 pb-10">
          <!-- specific user menu -->
          <slot name="items"></slot>

          <slot name="extraItems"></slot>
        </v-list>
        <!--    <template v-slot:append>
       <div class="text-center pa-4 mt-2 logo-bottom" :style="{ backgroundColor: $vuetify.theme.themes.light.accent }">
            <img height="25" src="images/powered_by.svg" alt="Powered by SchoolTry Tertiary" class="w-100">
          </div>
        </template> -->
      </v-navigation-drawer>
    </v-card>

    <!-----------------end navigation drawer ---------------------->

    <v-app-bar
      app
      color="primary"
      class="st-app-bar mx-md-70 mx-3 mx-2"
      height="100px"
      flat
    >
      <v-row no-gutters class="pt-5">
        <v-col md="4" class="d-inline-flex">
          <v-app-bar-nav-icon
            @click.stop="drawer=!drawer"
          >
          <template v-slot:default>
        <v-icon :style="{color: 'var(--primaryText)'}">mdi-menu</v-icon>
      </template>
    </v-app-bar-nav-icon>
          <div>
            <v-toolbar-title
              class="st-app-bar-title"
              v-if="auth_user && $vuetify.breakpoint.smAndUp"
            >
              Welcome
              {{
                auth_user.fullname
                  ? auth_user.fullname
                  : `${auth_user.firstname} ${auth_user.lastname}`
              }}
            </v-toolbar-title>
            <span class="st-app-bar-time">{{ currentDateTime }}</span>
          </div>
        </v-col>
        <v-col md="8" class="text-right">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!auth_user"
            icon
            class="mr-5"
            @click.prevent="switchComponent('Home')"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-btn
            class="mr-5"
            color="primary"
            v-if="!auth_user"
            @click.prevent="switchComponent('Login')"
            >Sign In</v-btn
          >
          <!-- Download button -->
          <FileDownloadList />
          <!-- /Download button -->

          <!-- <v-btn icon class="mr-5 st-bg-icon">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn> -->

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs}">
                    <v-btn
                     v-bind="attrs" v-on="on"
                     icon class="mr-5 st-bg-icon">
                        <v-icon>
                        mdi-bell-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Notifications</span>
            </v-tooltip>

          <!------------------------ begin switch role drop down menu markup"--------------------------->
          <v-menu
            :close-on-content-click="false"
            :close-on-click="false"
            v-model="showRoleSwitchMenu"
            offset-y
            style="max-width: 550px"
            transition="slide-y-transition"
            left
            v-if="auth_user && multiRoles"
          >
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="mr-5 st-bg-icon" v-bind="attrs" v-on="on">
                <v-icon>mdi-account-switch-outline</v-icon>
              </v-btn>
            </template> -->
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                        v-bind="attrs" v-on="{...tooltip, ...menu}"
                        icon class="mr-5 st-bg-icon">
                            <v-icon>
                            mdi-account-switch-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Roles</span>
                </v-tooltip>
            </template>

            <v-list>
              <!-- <v-list-item v-for="(roleName, index) in appointment_names" :key="index" @click="goToRoute(roleName)"> -->
              <v-list-item
                v-for="(roleName, index) in userRoleNames"
                :key="index"
                @click="switchRole(roleName)"
              >
                <v-list-item-icon>
                  <v-icon>{{ getRoleIcon(roleName) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ roleName }}</v-list-item-title>
                </v-list-item-content>

                <!-- Check if roleName is "level adviser" -->
                <!-- <v-list v-show="roleName == 'Level Adviser'">

                </v-list> -->
              </v-list-item>
              <!-- <v-list > -->
              <v-list-item>
                <v-list-group
                  no-action
                  sub-group
                  v-show="level_advising_appointments.length > 0"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Level Advisings</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <!-- Nested list for level advisers -->
                  <v-list-item
                    v-for="(
                      levelAdvising, innerIndex
                    ) in level_advising_appointments"
                    :key="innerIndex"
                    @click="
                      () => {
                        levelAdvisingStore.setValues(
                          levelAdvising.department_id,
                          levelAdvising.student_academic_level_id,
                          levelAdvising.department_name,
                          levelAdvising.student_academic_level_name,
                          levelAdvising.programme_id,
                          levelAdvising.programme_name
                        );
                        showRoleSwitchMenu = false;
                        $router.push({
                          path: '/academic-adviser/overview',
                          query: {
                            level: levelAdvising.student_academic_level_id,
                            programme: levelAdvising.programme_id,
                          },
                        });
                      }
                    "
                  >
                    <!-- Render properties of levelAdvising -->
                    <v-list-item-content>
                      <v-list-item-title>{{
                        levelAdvising.student_academic_level_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        levelAdvising.department_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item>

              <v-list-item>
                <v-list-group
                  no-action
                  sub-group
                  v-show="director_appointments.length > 0"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Directors</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <!-- Nested list for directors -->
                  <v-list-item
                    v-for="(
                      director_appointment, innerIndex
                    ) in director_appointments"
                    :key="innerIndex"
                    @click="goToDestination(director_appointment)"
                  >
                    <!-- Render properties of director -->
                    <v-list-item-content>
                      <v-list-item-title>{{
                        director_appointment.acronym
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item>

              <v-list-item>
                <v-list-group
                  no-action
                  sub-group
                  v-show="deputy_director_appointments.length > 0"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Deputy Directors</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <!-- Nested list for directors -->
                  <v-list-item
                    v-for="(
                      deputy_director_appointment, innerIndex
                    ) in deputy_director_appointments"
                    :key="innerIndex"
                    @click="goToDestination(deputy_director_appointment)"
                  >
                    <!-- Render properties of director -->
                    <v-list-item-content>
                      <v-list-item-title>{{
                        deputy_director_appointment.acronym
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item>

              <v-list-item>
                <v-list-group
                  no-action
                  sub-group
                  v-show="cordinator_appointments.length > 0"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Cordinators</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <!-- Nested list for cordinators -->
                  <v-list-item
                    v-for="(
                      cordinator_appointment, innerIndex
                    ) in cordinator_appointments"
                    :key="innerIndex"
                    @click="goToDestination(cordinator_appointment)"
                  >
                    <!-- Render properties of cordinators -->
                    <v-list-item-content>
                      <v-list-item-title>{{
                        cordinator_appointment.acronym
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item>
            </v-list>
          </v-menu>
          <!------------------------ End switch role drop down menu markup --------------------------->
          <v-menu
            v-model="showMenu"
            offset-y
            style="max-width: 600px"
            transition="slide-y-transition"
            left
            v-if="auth_user"
          >
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="mr-5 st-bg-icon" v-bind="attrs" v-on="on">
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </template> -->

            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                        v-bind="attrs" v-on="{...tooltip, ...menu}"
                        icon class="mr-5 st-bg-icon">
                            <v-icon>
                            mdi-cog-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Settings</span>
                </v-tooltip>
            </template>

            <v-list>
              <v-list-item
                link
                :to="{
                  name: 'Super Admin Profile',
                  params: { user: auth_user },
                }"
                v-if="userRoleNames[0] == 'Super Admin'"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-button"
                    >My Profile</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                @click.prevent="goToProfile(auth_user.institution)"
                v-if="userRoleNames[0] == 'Super Admin'"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-button"
                    >Settings</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="userRoleNames[0] == 'Super Admin'"
                @click.prevent="goToThemesSettings()"
              >
                <v-list-item-icon>
                  <v-icon>mdi-palette</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-button"
                    >Themes</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="userRoleNames[0] == 'Super Admin'"
                @click.prevent="goToAdminSupport(auth_user.institution)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-lifebuoy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-button"
                    >Support</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-else
                @click.prevent="
                  goToOtherSupport(auth_user.institution, userRoleNames[0])
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-lifebuoy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-button"
                    >Support</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-button"
                    >Logout</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <!--
            <v-card width="160">
            <v-row>
                <v-col>
                    <router-link :to="{ name: 'Super Admin Profile', params: {user: auth_user} }">
                    <v-btn plain >
                        <v-icon left>
                        mdi-account-circle
                        </v-icon>
                        <span>My Profile</span>
                    </v-btn>
                    </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                <v-btn plain @click.prevent="goToProfile(auth_user.institution)">
                    <v-icon left>
                    mdi-cog
                    </v-icon>
                    <span>Settings</span>
                </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                <v-btn plain @click.prevent="">
                    <v-icon left>
                    mdi-lifebuoy
                    </v-icon>
                    <span>Support</span>
                </v-btn>
                </v-col>
                <v-spacer></v-spacer>

                <v-col>
                <v-btn plain @click.prevent="logout()">
                    <v-icon left>
                    mdi-logout-variant
                    </v-icon>
                    <span>Logout</span>
                </v-btn>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            </v-card> -->
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import UserAPI from "../../apis/User";
import InstituteAPI from "../../apis/Institute";
import FileDownloadList from "../FileDownloadList.vue";
import { useInstituteStore } from "../../stores/instituteStore";
import { useUserStore } from '../../stores/userStore.js'
import RoleAPI from "../../apis/Role";
export default {
  components: { FileDownloadList },
  props: { authenticatedUser: Object },

  data() {
    return {
      auth_user: null,
      currentDateTime: null,
      showMenu: false,
      showRoleSwitchMenu: false,
      userRoleNames: [],
      userRoles: [],
      appointment_names: [],
      appointments: [],
      drawer: true,
      myRole: "",
      showDialog: false,
      bg_color: "",
      level_advising_appointments: [],
      is_level_adviser: 0,

      instituteStore: useInstituteStore(),

      director_appointments: [],
      is_director: 0,

      deputy_director_appointments: [],
      is_deputy_director: 0,

      cordinator_appointments: [],
      is_cordinator: 0,
      //---------------------------
      // items: [     //----------app bar dropdown contents: icons, text and routes
      //   { text: 'My Profile', icon: 'mdi-account-circle', route: 'profile', clickHandler: '' },
      //   { text: 'Settings', icon: 'mdi-cog', route: 'settings', clickHandler: '' },
      //   { text: 'Themes', icon: 'mdi-palette', route: 'themes', clickHandler: '' },
      //   { text: 'Support', icon: 'mdi-lifebuoy', route: 'support', clickHandler: '' },
      //   { text: 'Logout', icon: 'mdi-logout-variant', route: 'Login', clickHandler: 'logout()' },
      // ],
    };
  },
  computed: {
    multiRoles() {
      return this.userRoleNames.length + this.is_level_adviser > 1 || this.userRoleNames.length + this.is_director > 1 || this.userRoleNames.length + this.is_deputy_director > 1 || this.userRoleNames.length + this.is_cordinator > 1
        ? true
        : false;
    },
  },

  methods: {
    m() {},
    switchComponent(comp) {
      this.$router.push({ name: comp });
    },

    //-------------------------------------------------------------------------------------
    goToProfile(item) {
      this.$router.push({
        name: "Institution Profile",
        params: { institution: item },
      });
    },

    goToThemesSettings() {
      this.$router.push({
        name: "Institution Theme",
      });
    },

    goToAdminSupport(item) {
      this.$router.push({
        name: "Admin Support",
        params: { institution: item },
      });
    },
    goToOtherSupport(item, role) {
      switch (role) {
        case "Lecturer":
          this.$router.push({
            name: "Lecturer Support",
            params: { institution: item },
          });
          break;

        default:
          break;
      }
    },

    logout() {
      //----Logout button click event handler
      UserAPI.logout().then(() => {
        localStorage.removeItem("auth");
        localStorage.removeItem("tertiary_token");
        return this.$router.push({ name: "Home" });
      });
    },

    getHomePage() {
      if (
        localStorage.getItem("myRole") === "Candidate" &&
        localStorage.getItem("type") === "undergraduate"
      ) {
        localStorage.clear();
        // return  "Undergraduate Candidate Login";
        let home =
          this.authenticatedUser.institution.ownership === 1
            ? "Undergraduate Candidate"
            : "Applicant";
        return home;
      }

      if (
        localStorage.getItem("myRole") === "Candidate" &&
        localStorage.getItem("type") === "postgraduate"
      ) {
        localStorage.clear();
        return "Postgraduate Candidate Login";
      }
      localStorage.clear();
      sessionStorage.clear();
      return "Home";
    },

    //-----------------------------------
    getRoleIcon(roleName) {
      switch (roleName) {
        case "Dean of Faculty":
          return "mdi-account-tie-outline";
        case "Deputy Dean":
          return "mdi-account-tie";
        case "Faculty Exam Officer":
          return "mdi-account-tie";
        case "HOD":
          return "mdi-account-tie";
        case "Department Exam Officer":
        return "mdi-account-tie";
        case "Lecturer":
          return "mdi-account-check";
        case "Level Adviser":
          return "mdi-account-check";
        case "Director":
          return "mdi-account-tie"
        default:
          return "mdi-account";
      }
    },

    goToDestination(appointment){
      switch (appointment.entity_type) {
        case "Institute":
          this.navigateTo('Institute', appointment, 'institute');
        break;
        case "AcademicUnit":
          this.navigateTo('Director Dashboard', appointment, 'appointment');
        break;
        case "InstituteDepartment":
          this.navigateTo('Cordinator Dashboard', appointment, 'designated_unit');
        break;
        default:
        break;
      }
      this.showRoleSwitchMenu = false;
    },

    linkToInstitute(institute){
      const instituteString = JSON.stringify(institute);
      sessionStorage.setItem('institute', instituteString);
      const id = institute.id;
      this.$router.push({ name: 'Switch Role' }).then(() => {
        this.$router.push({ name: 'Institute', params: { id } });
      });
    },

    linkToCordinator(designated_unit){
        const designated_unit_string = JSON.stringify(designated_unit);
        sessionStorage.setItem('designated_unit', designated_unit_string);
        const id = designated_unit.id;
        this.$router.push({ name: 'Switch Role' }).then(() => {
          this.$router.push({ name: 'Cordinator Dashboard', params: { id } });
        });
    },

    linkToDirectorPage(director_appointment){
      const appointmentString = JSON.stringify(director_appointment);
        sessionStorage.setItem('appointment', appointmentString);
        const id = director_appointment.id;
        this.$router.push({ name: 'Switch Role' }).then(() => {
          this.$router.push({ name: 'Director Dashboard', params: { id } });
        });
    },

    navigateTo(routeName, entity, sessionKey) {
      const entityString = JSON.stringify(entity);
      sessionStorage.setItem(sessionKey, entityString);
      if(sessionKey === 'institute'){
        this.instituteStore.setInstitute(entity);
      }
      const id = entity.id;
      this.$router.push({ name: 'Switch Role' }).then(() => {
        this.$router.push({ name: routeName, params: { id } });
      });
    },

    switchRole(role_name){
      let selectedRole = this.userRoles.find((role) => role.name === role_name);
      this.roleStore.setRole(selectedRole);
      this.switchSessionRole(role_name); // update current_role in session at the backend
      // this.$router.push({ name: 'Switch Role' }).then(() => {
      //   this.$router.push({ name: this.getRoleRouteName(role_name) });
      // });
    },

    //-----------------------------
    getRoleRouteName(roleName) {
      let selectedRole = this.userRoles.find((role) => role.name === roleName);
      if (selectedRole.type === "Admin") {
        return "Admin";
      }

      switch (roleName) {
        case "Dean of Faculty":
          return "Faculty Dashboard";
        case "Deputy Dean":
          return "Faculty Dashboard";
        case "Faculty Exam Officer":
          return "Faculty Exam Officer Dashboard";
        case "Faculty Officer":
          return "Faculty Officer Dashboard";
        case "Faculty Accountant":
          return "Faculty Accountant Dashboard";
        case "HOD":
          return "Department Dashboard";
        case "Level Adviser":
          return "Academic Adviser Dashboard";
        case "Lecturer":
          return "Lecturer";
        case "Department Exam Officer":
          return "Department Exam Officer Dashboard";
        case "Director":
          return "Institute";
        case "Cordinator":
          return "Cordinator Dashboard";
        case "Hostel Manager":
          return "Hostel Manager Dashboard";
        default:
          return "Login";
      }
    },


    switchSessionRole(role_name) {
      RoleAPI.switch(role_name)
          .then(() => {
              this.$router.push({ name: 'Switch Role' }).then(() => {
                this.$router.push({ name: this.getRoleRouteName(role_name) });
              });
          })
          .catch(error => {
              this.handleErrorToast(error);
          });
    },

    // getContrastColor(colorName) {
    //   const colorElement = document.createElement("div");
    //   colorElement.style.color = colorName;
    //   document.body.appendChild(colorElement);
    //   const computedColor = window.getComputedStyle(colorElement).color;
    //   document.body.removeChild(colorElement);

    //   // Convert computed color to RGB
    //   const rgbMatch = computedColor.match(/\d+/g);
    //   if (rgbMatch) {
    //     const [r, g, b] = rgbMatch.map(Number);

    //     // Calculate perceived brightness
    //     const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    //     // Prioritize black on light colors and white on deep colors
    //     return brightness >= 50 ? "text-dark" : "text-light";
    //   }

    //   // Default to 'text-dark' if RGB extraction fails
    //   return "text-dark";
    // },
  },

  created() {
    const d = new Date();
    this.currentDateTime = d.toDateString();
    this.auth_user = this.userStore.auth;
    this.userRoles = this.userStore.auth.roles;
    // this.userRoleNames = this.userStore.getRoles;

    if (this.userStore.getRoles.includes("Level Adviser")) {
      this.is_level_adviser = 1;
    }
    if (this.userStore.getRoles.includes("Director")) {
      this.is_director = 1;
    }
    if (this.userStore.getRoles.includes("Deputy Director")) {
      this.is_deputy_director = 1;
    }
    if (this.userStore.getRoles.includes("Cordinator")) {
      this.is_cordinator = 1;
    }
    this.userRoleNames = this.userStore.getRoles.filter(
      (item) => item !== "Level Adviser" && item !== "Director" && item !== "Deputy Director" && item !== "Cordinator"
    );

    UserAPI.auth().then((response) => {
      this.level_advising_appointments =
        response.data.data.level_advising_appointments;

        this.director_appointments =
        response.data.data.director_appointments;

        this.deputy_director_appointments =
        response.data.data.deputy_director_appointments;

        this.cordinator_appointments =
        response.data.data.cordinator_appointments;
    });
  },

  mounted() {
    var retrievedUser = useUserStore().auth_user;

    this.auth_user = this.userStore.auth;

    this.appointments = retrievedUser.appointments;
    this.appointment_names = retrievedUser.appointment_names;

    let secondary_color = this.$vuetify.theme.themes.light.secondary;
    // change background color of elements of class logo-bottom to secondary color on mouse over
    let logo_bottom = document.getElementsByClassName("logo-bottom");
    for (let i = 0; i < logo_bottom.length; i++) {
      logo_bottom[i].style.backgroundColor = secondary_color;
    }
    this.bg_color = sessionStorage.getItem("primaryColor");

  },
};
</script>

<style scoped>
  .over {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: black;
      opacity: 0.5;
      z-index: 999;
      text-align: center;
}

.text-dark {
  color: #000000; /* Dark text color */
}

.text-light {
  color: #ffffff; /* Light text color */
}

/* Add any other styling as needed */
</style>
